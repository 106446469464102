import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Pie, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';
import Login from './Login';

ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [analysis, setAnalysis] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (loggedIn) {
      // Fetch data from the backend only if logged in
      axios
        // .get('http://localhost:5005/api/analyze')
        .get('https://smarc2k24.online/api/analyze')
        .then((response) => {
          setAnalysis(response.data);
          setLoading(false);
          console.log(response.data);
        })
        .catch((error) => {
          setError('Error fetching data');
          setLoading(false);
        });
    }
  }, [loggedIn]);

  if (!loggedIn) {
    return <Login onLogin={setLoggedIn} />;
  }

  if (loading)
    return (
      <div className='flex justify-center items-center h-screen text-xl'>
        Loading...
      </div>
    );
  if (error)
    return (
      <div className='flex justify-center items-center h-screen text-xl text-red-500'>
        {error}
      </div>
    );

  // Pie chart for accommodation preferences
  const accommodationData = {
    labels: Object.keys(analysis.accommodationCount),
    datasets: [
      {
        label: 'Accommodation Preferences',
        data: Object.values(analysis.accommodationCount),
        backgroundColor: ['#ff6384', '#36a2eb', '#ffce56', '#4bc0c0'],
      },
    ],
  };

  // Pie chart for gender distribution
  const genderData = {
    labels: Object.keys(analysis.genderDistribution),
    datasets: [
      {
        label: 'Gender Distribution',
        data: Object.values(analysis.genderDistribution),
        backgroundColor: ['#ff6384', '#36a2eb'],
      },
    ],
  };

  // Bar chart for college distribution
  const collegeData = {
    labels: Object.keys(analysis.collegeDistribution),
    datasets: [
      {
        label: 'Number of Students',
        data: Object.values(analysis.collegeDistribution),
        backgroundColor: '#36a2eb',
      },
    ],
  };

  // Bar chart for year of study distribution
  const yearOfStudyData = {
    labels: Object.keys(analysis.yearOfStudyDistribution),
    datasets: [
      {
        label: 'Number of Students',
        data: Object.values(analysis.yearOfStudyDistribution),
        backgroundColor: '#ff6384',
      },
    ],
  };

  // Bar chart for event participation
  const eventParticipationData = {
    labels: Object.keys(analysis.eventParticipation),
    datasets: [
      {
        label: 'Event Participation',
        data: Object.values(analysis.eventParticipation),
        backgroundColor: '#ffce56',
      },
    ],
  };

  // Extract boys and girls counts
  const numberOfBoys = analysis.genderDistribution['M'] || 0;
  const numberOfGirls = analysis.genderDistribution['F'] || 0;

  // Example facts
  const topAccommodation = Object.keys(analysis.accommodationCount).reduce(
    (a, b) =>
      analysis.accommodationCount[a] > analysis.accommodationCount[b] ? a : b
  );
  const topAccommodationCount = analysis.accommodationCount[topAccommodation];
  const totalParticipants = Object.values(analysis.genderDistribution).reduce(
    (a, b) => a + b,
    0
  );

  // Calculate percentages
  const topAccommodationPercentage = (
    (topAccommodationCount / totalParticipants) *
    100
  ).toFixed(2);
  const averageContribution = (
    analysis.totalAmount / totalParticipants
  ).toLocaleString('en-IN', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <div className='min-h-screen bg-gray-100 p-8'>
      <div className='container mx-auto'>
        <h1 className='text-4xl font-bold text-center text-gray-800 mb-12 mt-20'>
          Analysis Dashboard
        </h1>

        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12'>
          {/* Total Amount */}
          <div className='bg-white p-6 rounded-lg shadow-lg'>
            <h3 className='text-xl font-semibold text-center mb-4'>
              Total Amount
            </h3>
            <p className='text-3xl font-bold text-center'>
              ₹{' '}
              {analysis.totalAmount.toLocaleString('en-IN', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>

          {/* Number of Boys */}
          <div className='bg-white p-6 rounded-lg shadow-lg'>
            <h3 className='text-xl font-semibold text-center mb-4'>
              Number of Boys
            </h3>
            <p className='text-3xl font-bold text-center'>{numberOfBoys}</p>
          </div>

          {/* Number of Girls */}
          <div className='bg-white p-6 rounded-lg shadow-lg'>
            <h3 className='text-xl font-semibold text-center mb-4'>
              Number of Girls
            </h3>
            <p className='text-3xl font-bold text-center'>{numberOfGirls}</p>
          </div>
        </div>

        {/* Key Facts */}
        <div className='bg-white p-6 rounded-lg shadow-lg mb-12'>
          <h3 className='text-xl font-semibold text-center mb-4'>Key Facts</h3>
          <ul className='text-lg text-gray-700'>
            <li className='mb-2'>
              • The most popular accommodation is{' '}
              <strong>{topAccommodation}</strong>, preferred by{' '}
              <strong>{topAccommodationPercentage}%</strong> of participants.
            </li>
            <li className='mb-2'>
              • The average contribution per participant is{' '}
              <strong>₹{averageContribution}</strong>.
            </li>
            {/* Add other key facts here */}
          </ul>
        </div>

        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8'>
          {/* Accommodation Preferences */}
          <div className='bg-white p-6 rounded-lg shadow-lg'>
            <h3 className='text-xl font-semibold text-center mb-4'>
              Accommodation Preferences
            </h3>
            <Pie data={accommodationData} />
          </div>

          {/* Gender Distribution */}
          <div className='bg-white p-6 rounded-lg shadow-lg'>
            <h3 className='text-xl font-semibold text-center mb-4'>
              Gender Distribution
            </h3>
            <Pie data={genderData} />
          </div>

          {/* College Distribution */}
          <div className='bg-white p-6 rounded-lg shadow-lg col-span-1 md:col-span-2'>
            <h3 className='text-xl font-semibold text-center mb-4'>
              College Distribution
            </h3>
            <Bar data={collegeData} />
          </div>

          {/* Year of Study Distribution */}
          <div className='bg-white p-6 rounded-lg shadow-lg col-span-1 md:col-span-2'>
            <h3 className='text-xl font-semibold text-center mb-4'>
              Year of Study Distribution
            </h3>
            <Bar data={yearOfStudyData} />
          </div>

          {/* Event Participation */}
          <div className='bg-white p-6 rounded-lg shadow-lg col-span-1 md:col-span-2 xl:col-span-3'>
            <h3 className='text-xl font-semibold text-center mb-4'>
              Event Participation
            </h3>
            <Bar data={eventParticipationData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
