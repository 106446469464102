import React, { useState } from 'react';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    if (username === 'admin' && password === 'admin') {
      onLogin(true); // Notify parent that login is successful
    } else {
      setError('Invalid username or password');
    }
  };

  return (
    <div className='flex justify-center items-center min-h-screen bg-gray-100'>
      <div className='bg-white p-6 rounded-lg shadow-lg w-96'>
        <h2 className='text-2xl font-bold mb-4 text-center'>Login</h2>
        <form onSubmit={handleLogin}>
          <div className='mb-4'>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='username'
            >
              Username
            </label>
            <input
              type='text'
              id='username'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className='w-full px-3 py-2 border rounded'
              required
            />
          </div>
          <div className='mb-4'>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='password'
            >
              Password
            </label>
            <input
              type='password'
              id='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className='w-full px-3 py-2 border rounded'
              required
            />
          </div>
          <p className='text-xs'>
            Please contact{' '}
            <a href='https://eshway.com/contactus' className='no-underline'>
              <span className='text-black font-bold'>
                ESH
                <span className='text-[#d86dfc] font-bold'>WAY</span>
              </span>
            </a>{' '}
            to register another admin
          </p>
          {error && <p className='text-red-500 text-xs italic mb-4'>{error}</p>}
          <button
            type='submit'
            className='bg-[#1D3459] text-white font-bold py-2 px-4 rounded w-full'
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
