import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../context/GlobalContext';
import Select from 'react-select';
import Footer from './Footer';
import { BookOpen, Stethoscope } from 'lucide-react';
import 'bootstrap/dist/css/bootstrap.min.css';

// Registration section with closed registration status
const RegisterationClosedSection = () => {
  const { profession, setProfession } = useContext(GlobalContext);
  const { totalAmount, setTotalAmount } = useContext(GlobalContext);
  const navigate = useNavigate();

  // Handler for profession selection
  const handleSelect = (e) => {
    if (e.value === 'Student') {
      navigate('/registration/culturals');
    } else if (e.value === 'Doctor') {
      setTotalAmount(2499);
    }
    setProfession(e.value);
  };

  return (
    <>
      {/* Registration Closed Banner */}
      <div className='bg-red-600 text-white pr-4 pb-4 pl-4 pt-40 text-center font-bold'>
        <h2>Registrations are now closed!</h2>
        <p>We apologize for any inconvenience. Stay tuned for future events.</p>
      </div>

      {/* Content Section */}
      <div className='p-6 pb-0 bg-[#1d3459] min-h-screen'>
        <h2 className='text-2xl p-3 mb-0 text-[#1d3459] text-center font-semibold bg-[#F79E32]'>
          Registration Information
        </h2>
        <div className='bg-[#1d3459] pb-4'>
          <div className='p-2 pt-4'>
            <h2 className='text-2xl p-1 text-gray-200 text-center font-semibold'>
              Selection is now closed
            </h2>
          </div>
        </div>

        {/* Content describing the event */}
        <div className='p-4 text-gray-200'>
          <h3 className='text-xl font-semibold mb-2'>
            Thank you for your interest in SMARC
          </h3>
          <p className='text-lg mb-2'>
            We are no longer accepting registrations for this year's conference.
            We appreciate your enthusiasm and look forward to seeing you at
            future events.
          </p>
          <h3 className='text-xl mt-2 font-semibold mb-2'>
            What medicos would have received?
          </h3>
          <ul className='list-decimal ml-6'>
            <li>Delegate bag</li>
            <li>Magazine</li>
            <li>Note pad</li>
            <li>Pen</li>
            <li>Certificate</li>
          </ul>
          <h3 className='text-lg font-semibold mb-2'>Food:</h3>
          <ul className='list-disc ml-6 mb-4'>
            <li>Covered under basic registration on all three event days</li>
            <li>
              Savoring the flavors of Konaseema: A journey through its cuisine
            </li>
            <li>Both Veg and Non-Veg options available</li>
          </ul>
          <h3 className='text-lg font-semibold mb-2'>Accommodation:</h3>
          <ul className='list-disc ml-6 mb-4'>
            <li>Relax, Recharge, and Rejuvenate</li>
          </ul>
          <h3 className='text-lg font-semibold mb-2'>
            Certificates of Participation:
          </h3>
          <p className='mb-4'>
            Recognition given for participating in the SMARC
          </p>
          <h3 className='text-lg font-semibold mb-2'>APMC credit hours:</h3>
          <p className='mb-4'>Learning = Earning (credit hours)</p>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
};

// Registration pass component
const RegistrationPass = ({
  forGroup,
  title,
  price,
  description,
  icon: Icon,
}) => (
  <div className='bg-white rounded-lg p-4 mb-4 shadow-md'>
    <div className='flex items-center mb-2'>
      <div className='bg-orange-400 text-white py-1 px-3 rounded-full text-sm font-semibold'>
        FOR {forGroup.toUpperCase()}
      </div>
    </div>
    <div className='flex items-center justify-between mb-2'>
      <h2 className='text-2xl font-bold'>{title}</h2>
      <span className='bg-teal-100 text-teal-800 py-1 px-2 rounded text-xs font-semibold'>
        BASIC REGISTRATION PASS
      </span>
    </div>
    <p className='text-2xl font-bold mb-4'>₹{price}/-</p>
    <div className='flex items-start'>
      <Icon className='w-5 h-5 mr-2 text-blue-500 mt-1' />
      <p className='text-sm text-gray-600'>{description}</p>
    </div>
  </div>
);

export default RegisterationClosedSection;
